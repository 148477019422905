<template>
  <address-city-selector-withered-heath
    v-if="appConfig.VueSettingsPreRun.CitySelectorLayout === 'WitheredHeath'"
    :external-links-contacts="externalLinksContacts"
    :compact="compact"
    :show-phones="showPhones"
    :close="close"
    :change-city="changeCity"
    :cities-list="citiesList"
  />
</template>

<script setup lang="ts">
import type { Restaurant } from '~types/addressStore'
import type { CitySelectorProps } from '~types/props'
import type { CitySelect } from '~types/settingsVariants'

import { type GUID } from '@arora/common'

withDefaults(defineProps<CitySelectorProps>(), {
  compact: false,
  showPhones: true
})

const appConfig = useAppConfig()
const addressStore = useAddressStore()
const popupStore = usePopupStore()

const closedCurrentCityMatches = useCookie('closedCurrentCityMatches')

onMounted(async () => {
  if (import.meta.client) {
    await addressStore.initRestaurant()

    if (
      appConfig.VueSettingsPreRun.CitySelectShowOnEntrance &&
      citiesList.value &&
      (!appConfig.VueSettingsPreRun.CitySelectShowCloseButton ||
        (!appConfig.VueSettingsPreRun.CitySelectShowConfirm &&
          citiesList.value.length > 6))
    )
      await addressStore.showCityPopupAtEntrance({
        fromCityPopup: window.location.search.includes('fromCityPopup=true'),
        listCity: appConfig.VueSettingsPreRun.CitySelectDisplay as CitySelect,
        showCloseButton: appConfig.VueSettingsPreRun.CitySelectShowCloseButton,
        showPopupOnce: appConfig.VueSettingsPreRun.CitySelectShowOnce
      })
  }
})

const citiesList = computed<Restaurant[]>(() => {
  const restaurants: Restaurant[] | null = addressStore.Restaurants.data
  let total = [] as Restaurant[]

  const externalLinksAddress = appConfig.VueSettingsPreRun.ExternalLinks.filter(
    (l) => l.Type === 'address'
  )

  if (externalLinksAddress.length > 0)
    for (const link of externalLinksAddress) {
      total.push({
        Active: true,
        ExternalLink: link.Url,
        ID: '' as GUID,
        Name: link.Name ?? '',
        ShowRestaurantInSelect: true,
        SortWeight: link.SortWeight
      })
    }

  if (restaurants) {
    total = [
      ...total,
      ...Object.values(restaurants).filter((rest) => {
        return rest.Active && rest.ShowRestaurantInSelect
      })
    ]
  }

  return total
})

async function changeCity(): Promise<void> {
  await popupStore.openPopup({
    popupClosable: appConfig.VueSettingsPreRun.CitySelectShowCloseButton,
    popupName: 'citySelectorPopup'
  })
}

function close(): void {
  closedCurrentCityMatches.value = 'true'
}
</script>
